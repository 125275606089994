import React, { createContext, useCallback, useState } from 'react'
import useHeaderObserver from 'hooks/useHeaderObserver'

export const headerObserverContext = createContext({
  isHeaderVisible: false,
  headerRef: null,
})

export const HeaderProvider = ({ children }) => {
  const [headerNode, setHeaderNode] = useState(null)

  const headerRef = useCallback(node => {
    if (node !== null) {
      setHeaderNode(node)
    }
  }, [])
  const { isHeaderVisible } = useHeaderObserver(headerNode)

  const { Provider } = headerObserverContext

  return (
    <Provider
      value={{
        isHeaderVisible: isHeaderVisible,
        headerRef: headerRef,
      }}
    >
      {children}
    </Provider>
  )
}
