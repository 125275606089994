import { useState, useEffect } from 'react'

export const useHeaderObserver = node => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)

  useEffect(() => {
    // Early return if node is null
    if (!node) {
      return
    }

    const observer = new IntersectionObserver(
      entries => {
        setIsHeaderVisible(entries[0].isIntersecting)
      },
      {
        threshold: 0.3,
      },
    )

    observer.observe(node)

    return () => observer.disconnect()
  }, [node])

  return {
    isHeaderVisible,
  }
}

export default useHeaderObserver
