function checkTrackJsAvaialibity() {
  return typeof window.TrackJS !== 'undefined'
}

export const METADATA_USER_ID = 'userId'

const userMapper = {
  [METADATA_USER_ID]: 'uid',
}

function addMetaData(metadata, value) {
  if (checkTrackJsAvaialibity()) {
    window.TrackJS.addMetadata(metadata, value)
  } else {
    console.log('TrackJS not available')
  }
}

export const initUserMetaData = userData => {
  if (checkTrackJsAvaialibity()) {
    Object.keys(userMapper).forEach(key => {
      if (userMapper[key] === 'uid') {
        const value = userData[userMapper[key]] || 'GUEST'
        addMetaData(key, value)
      } else if (userData[userMapper[key]]) {
        const value = userData[userMapper[key]]
        addMetaData(key, value)
      }
    })
  }
}
