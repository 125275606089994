
// device info that is not the user agent
export const getDeviceInfo = () => {

  let result = {}

  try {
    result.mobile = window && window.matchMedia ? window.matchMedia("only screen and (max-width: 599px)").matches : -1 // 599px is from _variables.scss
    result.window = (!!window)
    result.width = window && window.screen ? window.screen.width : -1
    result.height = window && window.screen ? window.screen.height: -1
    result.maxTouchPoints = navigator ? navigator.maxTouchPoints : -1
    result.orientation = window && window.screen && window.screen.orientation ? window.screen.orientation.type : "unsupported"
    result.angle = window && window.screen && window.screen.orientation ? window.screen.orientation.angle : "unsupported"
  } catch (e) {
    console.error(`getDeviceInfo`, e)
  }

  return result

}

// we use URLSearchParams to encode a bunch of parameters into a single string
// that can be deconstructed later
export const getDeviceInfoHeader = () => {

    try {
        const info = getDeviceInfo()
        let params = new URLSearchParams();
        for (const key in info ) {
            params.append(key, info[key])
        }
    
        return params.toString();    
    } catch (e) {
        return `error=${e}`
    }
}