import localStorageWrapper from 'util/LocalStorageWrapper'
import sessionStorageWrapper from 'util/SessionStorageWrapper'
import { getDeviceInfoHeader } from 'util/device'

/*
    all of our service calls look the same.
    this removes the boilerplate.
*/

// Utility function to run JSON.parse without failing method, due to some returned data inconsistencies
export const isJSON = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
function encodeQueryString(params) {
  const keys = Object.keys(params)
  return keys.length
    ? '?' +
        keys
          .map(
            key =>
              encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
          )
          .join('&')
    : ''
}

// ----------------------------------------------------------------------------

/*
_Fetcher - a drop-in replacement for our legacy doPost, doGet helper methods.
It ensures that all API calls include a header that includes an auth token.

A single instance of this class is exported in this module as 'API'.

Instead of: 
    import { doPost } from "./common"

    doPost('/foo')

Use:
    import { API } from "./common"

    API.doPost('/foo')


*/
function _Fetcher(API_URL) {
  this.API_URL = API_URL
  this.token = localStorageWrapper.getItem('dbtoken')
}

_Fetcher.prototype.setApiUrl = function (API_URL) {
  this.API_URL = API_URL
}

_Fetcher.prototype.setToken = function (token) {
  this.token = token
  localStorageWrapper.setItem('dbtoken', token)
}

_Fetcher.prototype.getToken = function () {
  return this.token
}

_Fetcher.prototype.doPost = function (path, params) {
  try {
    const url = new URL(`${this.API_URL}${path}`)

    let headers = {
      Accept: 'application/json, text/plain',
      'Content-Type': 'application/json',
      'x-btm-device': getDeviceInfoHeader(),
    }

    if (this.token) {
      headers['Authorization'] = `Token: ${this.token}`
    }

    return fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.text().then(data => {
        if (response.status !== 200) {
          throw new Error(response.status)
        } else {
          return {
            status: response.status,
            success: response.ok,
            detail: isJSON(data) ? JSON.parse(data) : data,
          }
        }
      })
    })
  } catch (e) {
    console.log(e)
    return new Promise((resolve, reject) => {
      // eslint-disable-line no-unused-vars
      reject({
        success: false,
        status: -1,
        message:
          'Unable to communicate with server. Please check your internet connection.',
      })
    })
  }
}

/*
  doPost2 / doGet2 return the response object to the caller whereas doPost / doGet do not. 
  doPost2 / doGet2 gives components more control over how to fail 
*/
_Fetcher.prototype.doPost2 = function (path, params) {
  const url = new URL(`${this.API_URL}${path}`)

  let headers = {
    Accept: 'application/json, text/plain',
    'Content-Type': 'application/json',
    'x-btm-device': getDeviceInfoHeader(),
  }

  if (this.token) {
    headers['Authorization'] = `Token: ${this.token}`
  }

  return fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'include',
    headers: headers,
    body: JSON.stringify(params),
  }).then(response => {
    return response
    // }).catch((e) => {
    //     console.log(e)
  })
}

_Fetcher.prototype.doPost2WithFormData = function (path, params = {}) {
  const formData = new FormData()
  for (let key in params) {
    formData.append(key, params[key])
  }
  const url = new URL(`${this.API_URL}${path}`)

  let headers = {
    Accept: 'application/json',
    'x-btm-device': getDeviceInfoHeader(),
  }

  if (this.token) {
    headers['Authorization'] = `Token: ${this.token}`
  }

  return fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'include',
    headers: headers,
    body: formData,
  }).then(response => {
    return response
  })
}

_Fetcher.prototype.doGet = function (path, params) {
  try {
    const url = new URL(`${this.API_URL}${path}`)

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-btm-device': getDeviceInfoHeader(),
    }

    if (this.token) {
      headers['Authorization'] = `Token: ${this.token}`
    }

    return fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include',
      headers: headers,
      params: JSON.stringify(params),
    }).then(response => {
      return response.text().then(data => {
        if (response.status !== 200) {
          throw new Error(response.status)
        } else {
          return {
            status: response.status,
            success: response.ok,
            detail: isJSON(data) ? JSON.parse(data) : data,
          }
        }
      })
    })
  } catch (e) {
    return new Promise((resolve, reject) => {
      // eslint-disable-line no-unused-vars
      reject({
        success: false,
        message:
          'Unable to communicate with server. Please check your internet connection.',
      })
    })
  }
}

/*
    in the event of an error, doGet effectively swallows the error so the UI can't respond to specific error cases.
    this gives us more control over error handling
*/
_Fetcher.prototype.doGet2 = function (path, params) {
  let url = new URL(`${this.API_URL}${path}${encodeQueryString(params)}`)

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-btm-device': getDeviceInfoHeader(),
  }

  if (this.token) {
    headers['Authorization'] = `Token: ${this.token}`
  }

  return fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'include',
    headers: headers,
  }).then(response => {
    return response
    // }).catch((e) => {
    //     console.log(e)
  })
}

const API = new _Fetcher(process.env.DAILYBREAK_API_URL)

export { API }

// ----------------------------------------------------------------------------
// legacy methods still exported

export const doGet = (path, params) => {
  return API.doGet(path, params)
}

export const doPost = (path, params) => {
  return API.doPost(path, params)
}

export const doPost2 = (path, params) => {
  return API.doPost2(path, params)
}

export const doPost2WithFormData = (path, params) => {
  return API.doPost2WithFormData(path, params)
}

export const doGet2 = (path, params) => {
  return API.doGet2(path, params)
}

// ----------------------------------------------------------------------------

/*
    Generic class to handle starts/complete/awardPoints
    This class manages nonces so steps don't need to.
    The nonce is generated by the server on a call to start().
    This class sends the same nonce value back to the server on calls to awardPoints() and complete()

    usage:
    const service = new StepService('article', breakId, stepId)

    const response = await service.start()
    const response = await service.awardPoints(<optionalPoints>)
    const response = await service.complete()

    experimental:
    const response = await service.get('vote', {})
    const response = await service.post('vote', {})


*/
export function StepService(
  serviceSlug,
  breakId,
  stepId,
  breakType,
  platform,
  stepPosition = -1,
  stepType,
) {
  this.serviceSlug = serviceSlug
  this.breakId = breakId
  this.stepId = stepId
  this.breakType = breakType
  this.platform = platform
  this.stepPosition = stepPosition
  this.stepType = stepType
  this.nonce = undefined
}

StepService.prototype.start = async function (extra) {
  const base = {
    breakId: this.breakId,
    break_type: this.breakType,
    date_of_registration: '01/01/2024 00:00:00',
    event_name: 'step_start',
    isGuest: false,
    platform: this.platform,
    step_number: this.stepPosition,
    step_type: this.stepType,
    stepId: this.stepId,
    title: 'x',
    transaction_id: sessionStorageWrapper.getItem('transaction_id'),
    // TODO: we should not need these window checks since analytics
    // events should only be triggered by user interaction
    utm_campaign:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_campaign')
        : undefined,
    utm_content:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_content')
        : undefined,
    utm_source:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_source')
        : undefined,
    utm_medium:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_medium')
        : undefined,
    utm_term:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_term')
        : undefined,
  }

  const payload = Object.assign({}, base, extra)

  const response = await API.doPost2(`/${this.serviceSlug}/start`, payload)
  const clone = response.clone()

  if (response.status === 200) {
    // if .json() bombs, update the service to respond correctly
    const data = await response.json()
    if (data && data.nonce) {
      this.nonce = data.nonce
    }
  }

  return clone
}

StepService.prototype.complete = async function (skipped = true, extra) {
  const base = {
    breakId: this.breakId,
    break_type: this.breakType,
    date_of_registration: '01/01/2024 00:00:00',
    event_name: 'step_completion',
    isGuest: false,
    platform: this.platform,
    step_number: this.stepPosition,
    step_type: this.stepType,
    stepId: this.stepId,
    nonce: this.nonce,
    skipped: skipped,
    title: 'x',
    transaction_id: sessionStorageWrapper.getItem('transaction_id'),
    // TODO: we should not need these window checks since analytics
    // events should only be triggered by user interaction
    utm_campaign:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_campaign')
        : undefined,
    utm_content:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_content')
        : undefined,
    utm_source:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_source')
        : undefined,
    utm_medium:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_medium')
        : undefined,
    utm_term:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_term')
        : undefined,
  }

  const payload = Object.assign({}, base, extra)

  const response = await API.doPost2(`/${this.serviceSlug}/complete`, payload)

  return response
}

StepService.prototype.awardPoints = async function (optionalPoints, extra) {
  const base = {
    breakId: this.breakId,
    stepId: this.stepId,
    nonce: this.nonce,
    transaction_id: sessionStorageWrapper.getItem('transaction_id'),
    // TODO: we should not need these window checks since analytics
    // events should only be triggered by user interaction
    utm_campaign:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_campaign')
        : undefined,
    utm_content:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_content')
        : undefined,
    utm_source:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_source')
        : undefined,
    utm_medium:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_medium')
        : undefined,
    utm_term:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_term')
        : undefined,
  }

  const payload = Object.assign({}, base, extra)

  if (optionalPoints) {
    payload.points = optionalPoints
  }

  const response = await API.doPost2(
    `/${this.serviceSlug}/awardPoints`,
    payload,
  )

  return response
}

StepService.prototype.get = async function (endpoint, extra) {
  const base = {
    breakId: this.breakId,
    stepId: this.stepId,
    nonce: this.nonce,
    transaction_id: sessionStorageWrapper.getItem('transaction_id'),
    // TODO: we should not need these window checks since analytics
    // events should only be triggered by user interaction
    utm_campaign:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_campaign')
        : undefined,
    utm_content:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_content')
        : undefined,
    utm_source:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_source')
        : undefined,
    utm_medium:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_medium')
        : undefined,
    utm_term:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_term')
        : undefined,
  }

  const payload = Object.assign({}, base, extra)

  const response = await API.doGet2(`/${this.serviceSlug}/${endpoint}`, payload)

  return response
}

StepService.prototype.post = async function (endpoint, extra) {
  const base = {
    breakId: this.breakId,
    stepId: this.stepId,
    nonce: this.nonce,
    transaction_id: sessionStorageWrapper.getItem('transaction_id'),
    // TODO: we should not need these window checks since analytics
    // events should only be triggered by user interaction
    utm_campaign:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_campaign')
        : undefined,
    utm_content:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_content')
        : undefined,
    utm_source:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_source')
        : undefined,
    utm_medium:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_medium')
        : undefined,
    utm_term:
      typeof window != 'undefined'
        ? sessionStorageWrapper.getItem('utm_term')
        : undefined,
  }

  const payload = Object.assign({}, base, extra)

  const response = await API.doPost2(
    `/${this.serviceSlug}/${endpoint}`,
    payload,
  )

  return response
}
