import React, { useState } from 'react'

const themeContext = React.createContext({ styles: { mission: false } })

const ThemeProvider = ({ children }) => {
  const [themeStyle] = useState({ mission: false })
  const { Provider } = themeContext

  return (
    <Provider
      value={{
        styles: themeStyle,
      }}
    >
      {children}
    </Provider>
  )
}

export { themeContext, ThemeProvider }
