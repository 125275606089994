import localStorageWrapper from 'util/LocalStorageWrapper'

const isBrowser = () => typeof window !== 'undefined'

// this will get pulled from a service. for now, local storage
const getPoints = () => {
  if (isBrowser()) {
    if (localStorageWrapper.getItem('dailybreakPoints')) {
      return parseInt(localStorageWrapper.getItem('dailybreakPoints'))
    } else {
      return 0
    }
  }
}

const rewardPoints = points => {
  if (isBrowser()) {
    let current = getPoints()
    current = current + points
    localStorageWrapper.setItem('dailybreakPoints', current)
  }
}

export { rewardPoints, getPoints }
