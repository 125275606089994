/*

Keeps track of all the attributes that are sent along in analytics calls

This behaves similar to GTM's dataLayer where values are persisted between calls

This is ultimately just an object/map of key/value pairs. Structuring it this way gives us a
global variable and abstracts away some gory details.

in jsx:
    import analytics from "global/AnalyticsData"

    analytics.setUser(user)
    analytics.setBreak(brk)
    analytics.setStep(step)

    analytics.set({
        uid: user.uid
    })

    analytics.update({
        breakId: brk.id
    })

    analytics.trackEvent('event_name', {extra: 'thing'})

*/
import { sendEvent } from 'services/analytics'
import sessionStorageWrapper from 'util/SessionStorageWrapper'
class AnalyticsData {
  constructor() {
    this._data = {}
  }

  set(baseData) {
    this._data = Object.assign({}, baseData)
  }

  setUser(user) {
    const userData = {
      uid: user.isLoggedIn ? user.id : undefined,
      age: user.isLoggedIn ? user.age : undefined,
      gender: user.isLoggedIn ? user.gender : undefined,
      state: user.isLoggedIn ? user.state : undefined,
      zip: user.isLoggedIn ? user.zip : undefined,
      isGuest: !user.isLoggedIn,
      date_of_registration: user.isLoggedIn
        ? user.registrationDate
        : '01/01/2020 12:00:00', // TODO: Big Query requires registration date. What should this default to?
      original_utm_campaign: user.isLoggedIn ? user.utm_campaign : undefined,
      original_utm_content: user.isLoggedIn ? user.utm_content : undefined,
      original_utm_source: user.isLoggedIn ? user.utm_source : undefined,
      original_utm_medium: user.isLoggedIn ? user.utm_medium : undefined,
      original_utm_term: user.isLoggedIn ? user.utm_term : undefined,

      utm_campaign:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_campaign')
          : undefined,
      utm_content:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_content')
          : undefined,
      utm_source:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_source')
          : undefined,
      utm_medium:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_medium')
          : undefined,
      utm_term:
        typeof window != 'undefined'
          ? sessionStorageWrapper.getItem('utm_term')
          : undefined,
    }

    this._data = Object.assign({}, this._data, userData)
  }

  setBreak(brk) {
    // let tags = [];

    // try {
    //     if (brk) {
    //         tags = brk.breakTags.map((item, i) => {
    //             return item.label
    //         })
    //     }
    // } catch (e) {}

    const data = {
      break_id: brk ? brk.id : undefined,
      permalink: brk ? brk.permalink : undefined,
      break_type: brk ? brk.sponsoredType : undefined,
      title: brk ? brk.title : undefined,
      platform: brk ? brk.platform : undefined,
      step_total: brk && brk.steps ? brk.steps.length : undefined,
      challenge_id: brk ? brk.challengeId : undefined,
      lang: brk ? brk.lang : undefined,

      // content_category: brk ? brk.categories : undefined,
      // content_tags: tags
    }

    this._data = Object.assign({}, this._data, data)
  }

  setStep(step) {
    const data = {
      step_id: step ? step.id : undefined,
      step_type: step ? step.type : undefined,
      step_number: step ? step.position : undefined,
    }

    this._data = Object.assign({}, this._data, data)
  }

  update(additionalData) {
    this._data = Object.assign({}, this._data, additionalData)
    return this._data
  }

  merge(additionalData) {
    return Object.assign({}, this._data, additionalData)
  }

  get data() {
    return this._data
  }

  // @deprecated
  // whenever posible use breakEvent or stepEvent
  // it seems like we hit race conditions and end up mixing up data
  trackEvent(eventName, additionalData) {
    return sendEvent(eventName, this.merge(additionalData))
      .then(result => {
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      })
      .catch(e => {
        console.warn(e)
        return new Promise((resolve, reject) => {
          resolve()
        })
      })
  }

  /**
   * @typedef {Object} AnalyticsEventContext
   * @property {String} context_id
   * @property {String} context
   * @property {String} result_id
   * @property {String} result
   * @property {String} context_total for items in a list, this is the total number of items
   * @property {String} context_position for items in a list, this is the item's position, e.g. quiz question 2

   * 
   * @param {String} eventName 
   * @param {Object} step 
   * @param {AnalyticsEventContext} additionalData is context @see [confluence doc on event-context](https://thedailybreak.atlassian.net/wiki/spaces/D2/pages/1263304705/Events#Event-Context). 
   * 
   * Usage:
   ```
      analytics.stepEvent('step_interaction_pq_answer_selected', step, {
      context_id: questionId,
      context: question.text,
      result_id: answerId,
      result: answer.text,
      context_total: quiz.questions.length,
      context_position: state.position,
    })
   ```

   */
  stepEvent(eventName, step, additionalData) {
    this.setStep(step)
    const payload = Object.assign(
      {},
      { transaction_id: sessionStorageWrapper.getItem('transaction_id') },
      additionalData,
    )
    this.trackEvent(eventName, payload)
  }

  breakEvent(eventName, brk, additionalData) {
    this.setBreak(brk)
    this.setStep()
    const payload = Object.assign(
      {},
      { transaction_id: sessionStorageWrapper.getItem('transaction_id') },
      additionalData,
    )
    this.trackEvent(eventName, payload)
  }
}

export default new AnalyticsData()
