/*

Wrapper around the browser's Local Storage api. When local storage isn't available, data is written to the `window`.

It's means to be a drop-in replacement.

instead of:
window.localStorage.getItem('some_key')

use:
import localStorageWrapper from 'util/LocalStorageWrapper'
localStorageWrapper.getItem('some_key')


inspired by:
https://github.com/gatsbyjs/gatsby/blob/fb55ff6b0f/packages/gatsby-react-router-scroll/src/scroll-handler.tsx


*/
const BREAKTIME_LOCAL = `___btm_local`

class _LocalStorageWrapper {
  getItem(key) {
    try {
      const value = window.localStorage.getItem(key)
      return value === 'null' ? null : value // localStorage coerces null to "null". this fixes it.
    } catch (e) {
      // if (process.env.NODE_ENV !== `production`) {
      //     console.warn(`localStorage is not available.`)
      // }

      if (typeof window !== `undefined`) {
        if (window[BREAKTIME_LOCAL] && window[BREAKTIME_LOCAL][key]) {
          return window[BREAKTIME_LOCAL][key]
        }
      }

      return null
    }
  }

  removeItem(key) {
    try {
      const value = window.localStorage.removeItem(key)
      return value === 'null' ? null : value // localStorage coerces null to "null". this fixes it.
    } catch (e) {
      // if (process.env.NODE_ENV !== `production`) {
      //     console.warn(`localStorage is not available.`)
      // }
      if (typeof window !== `undefined`) {
        if (window[BREAKTIME_LOCAL] && window[BREAKTIME_LOCAL][key]) {
          return window[BREAKTIME_LOCAL][key]
        }
      }

      return null
    }
  }

  setItem(key, value) {
    // we leave JSON.stringify up to the caller
    // const storedValue = JSON.stringify(value)

    try {
      window.localStorage.setItem(key, value)
    } catch (e) {
      if (typeof window !== `undefined`) {
        if (window[BREAKTIME_LOCAL]) {
          window[BREAKTIME_LOCAL][key] = value
        } else {
          window[BREAKTIME_LOCAL] = {}
          window[BREAKTIME_LOCAL][key] = value
        }
      }
      // if (process.env.NODE_ENV !== `production`) {
      //     console.warn(`localStorage is not available.`)
      // }
    }
  }
}

const localStorageWrapper = new _LocalStorageWrapper()

export default localStorageWrapper
