import { doPost } from './common'

export const sendEvent = async (event_name, attributes) => {
  const payload = Object.assign({}, attributes, { event_name: event_name })

  try {
    await doPost(`/break/event`, payload)
  } catch (e) {
    console.log(e)
  }
}
