import React, { useState, useEffect } from 'react'
const resizeContext = React.createContext({ width: 1000 })

const ResizeProvider = ({ children }) => {
  let defaultWidth = 1000
  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth
  }
  const [innerWidth, setInnerWidth] = useState(defaultWidth)

  // const { Provider,Consumer } = resizeContext;
  const { Provider } = resizeContext
  const handleResize = () => {
    setInnerWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Provider
      value={{
        width: innerWidth,
      }}
    >
      {children}
    </Provider>
  )
}

export { resizeContext, ResizeProvider }
